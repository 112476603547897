<template>
  <div>
    <q-form ref="editForm">
      <c-card title="비상조치계획 상세" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn
              v-if="!disabled && editable"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="emerActPlanDocu"
              :mappingType="saveType"
              label="저장"
              icon="save"
              @beforeAction="savePlan"
              @btnCallback="saveCallback" 
            />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-sm-12 col-md-6 col-lg-6">
            <c-text
              :required="true" 
              :editable="editable"
              :disabled="disabled"
              label="계획서명"
              name="emergencyPlanName"
              v-model="emerActPlanDocu.emergencyPlanName">
            </c-text>
          </div>
          <div class="col-sm-6 col-md-3 col-lg-3">
            <c-text
              :editable="editable"
              :disabled="disabled"
              label="문서번호"
              name="documentNo"
              v-model="emerActPlanDocu.documentNo">
            </c-text>
          </div>
          <div class="col-sm-6 col-md-3 col-lg-3">
            <c-select
              required
              :editable="editable"
              :disabled="disabled"
              type="edit"
              codeGroupCd="EAP_TYPE_CD"
              itemText="codeName"
              itemValue="code"
              name="emergencyTypeCd"
              label="비상사태구분"
              v-model="emerActPlanDocu.emergencyTypeCd"
            ></c-select>
          </div>
          <div class="col-sm-6 col-md-3 col-lg-3">
            <c-text
              :required="true" 
              :editable="editable"
              :disabled="disabled"
              label="비상사태유형"
              name="emergencyClassCd"
              v-model="emerActPlanDocu.emergencyClassCd">
            </c-text>
          </div>
          <div class="col-sm-6 col-md-3 col-lg-3">
            <c-text
              suffix="명"
              :editable="editable"
              :disabled="disabled"
              type="number"
              label="피해예상인원"
              name="damageTypeExpectPeopleNumber"
              v-model="emerActPlanDocu.damageTypeExpectPeopleNumber">
            </c-text>
          </div>
          <div class="col-sm-6 col-md-3 col-lg-3">
            <c-plant 
              :required="true" 
              :disabled="disabled"
              :editable="editable" 
              type="edit" 
              name="plantCd" 
              v-model="emerActPlanDocu.plantCd"
              @datachange="plantChange" />
          </div>
          <div class="col-sm-6 col-md-3 col-lg-3">
            <c-radio
              :editable="editable"
              :disabled="disabled"
              :comboItems="useFlagItems"
              label="사용여부"
              name="useFlag"
              v-model="emerActPlanDocu.useFlag">
            </c-radio>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              label="예상피해사항 및 범위"
              :rows="7"
              name="expectDamageRange"
              v-model="emerActPlanDocu.expectDamageRange">
            </c-textarea>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              label="예상원인"
              :rows="7"
              name="expectCause"
              v-model="emerActPlanDocu.expectCause">
            </c-textarea>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              label="공정조치사항"
              :rows="7"
              name="processActionMatter"
              v-model="emerActPlanDocu.processActionMatter">
            </c-textarea>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              label="비상사태 대응시 유의사항"
              :rows="7"
              name="emergencyReactNotice"
              v-model="emerActPlanDocu.emergencyReactNotice">
            </c-textarea>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              label="대피절차"
              :rows="7"
              name="evacuationProcedures"
              v-model="emerActPlanDocu.evacuationProcedures">
            </c-textarea>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              label="비상대피 후 취해야할 업무"
              :rows="7"
              name="evacuationAfterAction"
              v-model="emerActPlanDocu.evacuationAfterAction">
            </c-textarea>
          </div>
        </template>
      </c-card>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>

import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'emergency-action-plan-docu-base',

  props: {
    emerActPlanDocu: {
      type: Object,
      default: () => ({
        sopEmergencyPlanId: '',
        plantCd: '',
        documentNo: '',
        emergencyPlanName: '',
        emergencyTypeCd: null,
        emergencyClassCd: '',
        damageTypeExpectPeopleNumber: '',
        expectDamageRange: '',
        expectCause: '',
        processActionMatter: '',
        emergencyReactNotice: '',
        evacuationProcedures: '',
        evacuationAfterAction: '',
        useFlag: 'Y',
        regUserId: '',
        chgUserId: '',
      }),
    },
  },
  data() {
    return {
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      tab: 'equipment',
      editable: true,
      searchUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
      saveUrl: '',
      saveType: 'POST',
      isSave: false,
      disabled: false,
      updateMode: false,
      getUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.sop.eap.planDocu.get.url;
      this.saveUrl = transactionConfig.sop.eap.planDocu.insert.url;
    },
    plantChange() {
      this.$emit('transInfo', { 
        plantCd: this.emerActPlanDocu.plantCd,  
        sopEmergencyPlanId: this.emerActPlanDocu.sopEmergencyPlanId,  
      })
    },
    savePlan() {
      if (this.emerActPlanDocu.sopEmergencyPlanId) {
        this.saveUrl = transactionConfig.sop.eap.planDocu.update.url;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.eap.planDocu.insert.url;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?', 
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.emerActPlanDocu.regUserId = this.$store.getters.user.userId
              this.emerActPlanDocu.chgUserId = this.$store.getters.user.userId

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.emerActPlanDocu.sopEmergencyPlanId) {
        this.emerActPlanDocu.sopEmergencyPlanId = _result.data
        this.$emit('changeStatus', this.emerActPlanDocu.sopEmergencyPlanId)
      }
    },
  }
};
</script>
